@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.toggle-btn {
  cursor: pointer;
  transition: transform .3s;
}

.delete,
.view,
.edit {
  cursor: pointer;
}

.delete:hover {
  color: brown;
}

.cardStyle {
  --b: 2px;
  --g: 2px;
  --c: #6d6c6c0b;

  cursor: pointer;
  transition: all ease;
  justify-content: space-between;
}

.cardStyle:hover {
  outline: var(--b) solid var(--c);
  outline-offset: var(--g);
}